.page-login{
    .login-panel{
        border-radius: 1rem;
        margin-bottom: 30px;
        @include media-breakpoint-up(md){
            margin-bottom: 100px;
        }
        @include media-breakpoint-up(lg){
            margin-bottom: 200px;
        }
    }
    background-image: url("../images/bg2.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}
