.page{
    &-file{
        .box-upload-file{
            .el-form-item{
                margin: 0;
            }
            background-color: #ffffff;
            padding: 10px;
            border-radius: 10px;
            margin-bottom: 10px;

            background-repeat: no-repeat;
            background-position: right 5% bottom 5%;
            .btn-form-upload{
                color: #0377b9;
                background-color: #ffffff;
                border: 1px dashed #717171;
                padding: 20px 30px;
            }
        }
        .file--title{
            //background-color: #0377b9;
            padding: 10px;
            border-radius: 15px 15px 0 0;
            display: block;
            justify-content: space-between;
            align-items: center;
            @include media-breakpoint-up(md){
                display: flex;
            }
            .title{
                margin: 0;
                padding-left: 10px;
                border-left: 3px solid #000000;
                //color: #ffffff;
            }
        }
    }
}
.el-dialog__title{
    word-break: break-all;
    display: block;
    padding-right: 20px;
}
