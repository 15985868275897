html, body {
    background-color: $bg_color;
    color: $text_color;
    font-family: $font-family-sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: $line-height-base;
    //user-select: none;
}
a{
    @include transition();
    outline: none;
    color: $text_color;
    svg{
        fill: $text_color;
        @include transition();
    }
    &:hover{
        color: $text_hover_color;
        text-decoration: none;
        svg{
            fill: $text_hover_color;
        }
    }
}
button{
    outline: none;
    box-shadow: none;
    &:hover,&:focus,&:active{
        box-shadow: none;
        outline: none;
    }
}
img{
    width: 100%;
    height: auto;
}

.fill-container{
    width: 100%;
    height: 100%;
}
#app{
    overflow: hidden;
}
.fade-enter-active,
.fade-leave-active{
    transition-duration: 0.6s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active{
    opacity: 0
}
#app{
    min-height: 100vh;
}
.page{
    // padding-top: 15px;
    // padding-left: 15px;
    // padding-right: 15px;
}
label{
    margin: 0;
}
.text-decoration-underline{
    text-decoration: underline;
    a{
        text-decoration: underline;
    }
}
.sidebar .el-card__header{
    padding: 10px 20px;
}
.sidebar-menu{
    height: 100vh;
    padding-bottom: 82px;
    overflow-y: auto;
}
// .sidebar .box-card{
//     margin-top: 20px;
// }
.box-card{
    .el-card__body {
        .el-checkbox-group{
            label{
                span.el-checkbox__label{
                    white-space: normal;
                }
            }
        }
    }
    &.over-scroll{
        .el-card__body {
            overflow-y: scroll;
            max-height: 200px;
            &::-webkit-scrollbar-track{
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                border-radius: 5px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar{
                width: 6px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar-thumb{
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                background-color: #c1c1c1;
            }
        }
    }
}
.cursor-pointer{
    cursor: pointer;
}
.header__nav{


}
.text-center > input.el-input__inner {
    text-align: center;
}
.el-input.is-disabled{
    .el-input__inner{
        color: #333;
        background: #fff;
    }
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
    color: white;
}
.el-table .cell{
    word-break: break-word;
}
.el-table__header{
    tr,th{
        background-color: #e2ebfd;
        color: #323232;
    }
    td,th{
        border-color: rgba(0,0,0,0.1);
    }
    th.is-leaf{
        border-color: rgba(0,0,0,0.1);
    }

}
strong{
    font-weight: 500;
}

.upload-thumb{
    width: fit-content !important;
}
.el-table,.el-dialog__body{
    color: #000000 !important;
}

.info-contact{
    &:after{
        content: '';
        width: 100%;
        height: 40px;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #0B4C5F
    }
    .el-popover__title{
        position: relative;
        z-index: 1;
        margin: 0;
        color: #ffffff;

    }
    &>div{
        margin-top: 20px;
    }
    &[x-placement^=top] .popper__arrow{

    }
}
.hocba__upload{
    max-width: 300px;
    position: relative;
    width: 300px !important;
    height: 300px;
    border: 1px solid #aaa;
    .vue-base64-file-upload-wrapper{
        position: absolute !important;
        top: 0;
        left: 0;
    }
}
.hocba__uploadfile{
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 300px;
}
.el-alert--info .el-alert__description{
    color: $text_color;
}
.el-alert--info.is-light .el-alert__title{
    color: $text_color;
}

.infoAccount{
    label{
        width: 170px;
        font-weight: 600;
        @include media-breakpoint-up(md){
            text-align: right;
        }
    }
}

.image-hs{
    .el-image{

    }
    .image-slot{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: #a2a2a2;
        color: #fff;
        font-size: 30px;
        width: 400px;
        height: 300px;
        border: 1px solid white;
    }
}
.el-button{
    &--secondary{
        background-color: #0b4c5fdb;
        border-color: #0b4c5fdb;
        color: #ffffff;
        &:hover{
            background-color: #0b4c5f;
            border-color: #0b4c5f;
            color: #ffffff;
        }
    }
}
.el-pager{
    li{
        &.active{
            a{
                &:hover{
                    color: #fff;
                }
                background-color: #409eff;
                color: #fff;
            }
        }
        background-color: transparent;
        a{
            margin: 0 5px;
            background-color: #fff;
            color: #606266;
            min-width: 30px;
            border-radius: 2px;
            padding: 0 4px;
            vertical-align: top;
            display: inline-block;
            font-size: 13px;
            height: 28px;
            line-height: 28px;
            cursor: pointer;
            box-sizing: border-box;
            text-align: center;
        }
    }
}
.avatar{
    display: inline-block;
    position: relative;
    .remove-avatar{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 20px;
        background-color: rgba(255,255,255,0.5);
    }
}
.add-mutile-image{
    width: 95% !important;
    @include media-breakpoint-up(lg){
        width: 800px !important;
    }
    .el-upload-list{
        @include media-breakpoint-up(lg){
            display: flex;
            max-height: 300px;
            overflow-y: auto;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
        }
        .el-upload-list__item{
            @include media-breakpoint-up(lg){
                width: 50%;
                flex: 0 50%;
            }
        }
    }
    .el-upload__tip{
        font-size: 13px;
    }
}