.page {
    &-user-info{
        .icon{
            font-size: 100px;
            border: 5px solid #000;
            border-radius: 50%;
            width: 150px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &-user {
        .user--title {
            background-color: #0377b9;
            padding: 10px;
            border-radius: 15px 15px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h6 {
                margin: 0;
                padding-left: 10px;
                border-left: 3px solid #ffffff;
                color: #ffffff;
            }
        }
    }

    .el-row {


        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    .bg-purple {
        background: #d3dce6;
    }

    .bg-purple-light {
        background: #e5e9f2;
    }

    .bg-smoke {
        background: #e8e8eb;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    .dts-content{
        padding:15px 0;
    }
    .el-col,
    .el-col-1,
    .el-col-2,
    .el-col-3,
    .el-col-4,
    .el-col-5,
    .el-col-6,
    .el-col-7,
    .el-col-8,
    .el-col-9,
    .el-col-10,
    .el-col-11,
    .el-col-12,
    .el-col-13,
    .el-col-14,
    .el-col-15,
    .el-col-16,
    .el-col-17,
    .el-col-18,
    .el-col-19,
    .el-col-20,
    .el-col-21,
    .el-col-22,
    .el-col-23,
    .el-col-24 {
        margin: 2px 0;
    }
    .image-circle-info{
        width: 200px;
        height: 200px;
        border-radius: 50%;
        max-width: 100%;
    }
}

.table-custom{
    thead{
        background-color: #09224e;
        color: #ffffff;
    }
}

.upload-demo{
    .el-upload-dragger{
        width: 320px;
    }
}


canvas#signId {
    cursor: crosshair;
}

.step3{
    margin-top: 10px;
    text-align: center;
    @include media-breakpoint-up(md){
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }
}

.display-color {
    display: flex;
    align-items: center;
    justify-content: center;
}