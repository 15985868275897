.page {
  &__header {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-position: right 5% bottom 5%;
  }

  &__title {
    .title {
      font-size: 1.3rem;
      margin-right: 10px;
    }
  }

  .el-table {
    .cell {
      word-break: break-word;
    }

    table {
      thead {
        tr {
          th {
            border-color: $text_navbar_sub;
            background-color: $primary-color;
            color: $text-light;
          }
        }
      }
    }

    .thongTinLopHoc {
      th {
        border-color: $text_navbar_sub;
        background-color: #193c8b;
        color: rgb(255, 255, 255);
      }
    }
  }

}

div#layout-none {
  height: 100vh;
}

.page-login {
  height: 100%;
}

@media print {
  div[data=page] {
    margin: 0;
  }
}

#detailHocBa .fancybox-slide--iframe .fancybox-content {
  max-height: 35cm !important;
}

.hocbaDetail div[data='page'] {
  .info {
    font-weight: 500;
    font-style: italic;
  }

  .table-bordered {
    border: 2px solid #333;

    th,
    td,
    tr {
      border-width: 2px;
      border-color: #222;
    }
  }

  .table {

    font-size: 14px;

    th {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      font-weight: 500;
      vertical-align: middle;
    }

    td {
      text-align: center;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      vertical-align: middle;
    }
  }
}

.listMon {
  position: relative;
}

.editDiem {
  border: 2px solid transparent;

  th,
  td,
  tr {
    border-width: 2px;
    border-color: transparent;
  }

  width: 50px;
  position: absolute;
  top: 0;
  right: -50px;

  thead {
    tr {
      height: 138px;
    }
  }
}

.el-dialog__body {
  word-break: break-word;
}

.el-loading-mask {
  background-color: rgba(0, 0, 0, 0.7);
}

.wrapper {
  .sidebar {
    &.mobile {
      .wrapper-content {
        margin-left: 0;
      }
    }
  }

  &.menu-open {
    .wrapper-content {
      @include media-breakpoint-up(md) {
        margin-left: 250px;
      }
    }
  }

  &.menu-close {
    .wrapper-content {

      @include media-breakpoint-up(md) {
        margin-left: 78px;
      }
    }
  }
}

.card-bieu-do {
  .el-row {
    >div {
      &.header_page {
        margin: 0;
      }

      margin-bottom: 10px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 30px;
      }
    }
  }
}
