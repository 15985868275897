.el-dialog{
    &__wrapper{

    }
    &__header{
        background-color: $primary_color;
    }
    &__title{
        color: $text_light !important;
    }
}
.bootstrap-dialog{
    .modal-dialog{
        .modal-header{
            background-color: $secondary_color;
        }
        .bootstrap-dialog-title{
            color: $text_light;
        }
    }
}
.modal-content{
    border: none;
}

.popupChuyenTruong{
    .el-dialog{
        width: 80%;
        @include media-breakpoint-up(md){
            width: 50%;
        }
        @include media-breakpoint-up(lg){
            max-width: 600px;
        }
    }

}

.popupEditDiem{
    .el-dialog{
        width: 70%;
        @include media-breakpoint-up(md){
            width: 60%;
        }
        @include media-breakpoint-up(lg){
            max-width: 700px;
        }
    }
}
.showSetting{
    .el-dialog{
        width: 70%;
        @include media-breakpoint-up(md){
            width: 60%;
        }
        @include media-breakpoint-up(lg){
            max-width: 500px;
        }
    }
}
.hisory-xuly{
    .el-dialog{
        width: 100%;
        height:100vh;
        @include media-breakpoint-up(md){
            //width: 95%;
        }
        @include media-breakpoint-up(lg){
            //max-width: 1000px;
        }
    }
}
.popupSuaDiem{
    .el-dialog{
        width: 90%;
        @include media-breakpoint-up(md){
            width: 70%;
        }
        @include media-breakpoint-up(lg){
            max-width: 900px;
        }
    }
}
.popupPhanQuyenBoMon{
    .el-dialog{
        width: 90%;
        @include media-breakpoint-up(md){
            width: 80%;
        }
        @include media-breakpoint-up(lg){
            width: 70%;
        }
    }
}

.popupThongTinDiemTongKet{
    .el-dialog{
        width: 95%;
        margin-top: 5vh !important;
        height: 90vh !important;
        @include media-breakpoint-up(md){
            width: 95%;
        }
        @include media-breakpoint-up(lg){
            max-width: 95%;
        }
    }
}
.otp__form{
    .el-dialog{
        width: 90%;
        @include media-breakpoint-up(md){
            width: 400px;
        }

    }
}
.alert-otp{
    .el-dialog{
        width: 80%;
        @include media-breakpoint-up(md){
            width: 400px;
        }

    }
}
.dialog__confirm,.dialog__alert{
    .el-dialog{
        width: 400px;
    }

}
.info-hocba{
    .el-dialog{
        width: 90%;
        @include media-breakpoint-up(lg){
            width: 1200px;
        }
    }
}
.info-hocba-fullview{
    .el-dialog{
        width: 100%;
        margin-top: 0 !important;
        @include media-breakpoint-up(lg){
            width: 100%;
            margin-top: 0 !important;
        }
    }
}
.add-hocba,.edit-hocba{
    .el-dialog{
        width: 90%;
    }
}

.updateInfoTruong{
    .el-dialog{
        width: 90%;
        @include media-breakpoint-up(lg){
            width: 600px;
        }
    }
}

.dts-noty-success{
    background: #67c23a !important;
    border-color: #67c23a !important;
}
.dts-noty-info{
    background: #909399 !important;
    border-color: #909399 !important;
}
.dts-noty-warning{
    background: #e6a23c !important;
    border-color: #e6a23c !important;
}
.dts-noty-error{
    background: #f56c6c !important;
    border-color: #f56c6c !important;
}
.el-message__content{
    color: white !important;
}
.el-message .el-icon-error {
    color: white !important;
}
.el-message .el-icon-success {
    color: white !important;
}
.el-message .el-icon-warning {
    color: white !important;
}
.el-message .el-icon-info {
    color: white !important;
}