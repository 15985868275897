.wrapper{
    .mobile--close{
        font-size: 32px;
        position: absolute;
        top: 15px;
        right: 15px;
        &.el-link--default{
            color: white;
        }
    }
    &.menu-open{
        .desktop{
            .el-aside.navbar__full{
                visibility: visible;
                transform: translate3d(0, 0, 0);
            }
        }

    }
    &.mobile-open{
        .overlay{
            position: fixed;
            display: block;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba(0,0,0,0.75);
        }
        overflow: hidden;
        height: 100vh;
        .mobile{
            .el-aside.navbar__full{
                visibility: visible;
                transform: translate3d(0, 0, 0);
            }
        }

    }
    &.menu-close{
        .el-aside.navbar__icon{
            visibility: visible;
            .logo img{
                margin: 0;
                width: 30px;
            }
            .name--display,.logout{
                display: none;
            }
        }

    }
}

.page{
    padding: 15px;
    @include media-breakpoint-up(md){
        padding: 15px 30px;
    }
}
.sidebar{

    &.desktop{
        display: none;
        @include media-breakpoint-up(md){
            display: block;
        }
    }
    &.mobile{

    }
    transition-duration: 200ms;
    position: fixed;
    z-index: 99;
    > .el-aside{
        overflow: inherit;
        position: fixed;
        &.navbar__full{
            transition-duration: 250ms;
            transition-property: transform;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 250px;
            min-height: 100vh;
            transform: translate3d(-100%, 0, 0);
        }

        background-image: linear-gradient($secondary_color,$primary_color);
        visibility: hidden;
        position: fixed;
        min-height: 100vh;
        height: 100vh;
        z-index: 1;
        top: 0;
    }

    .logo{
        .logout{
            font-size: 23px;
        }
        display: flex;
        align-items: center;
        justify-content: unset;
        img{
            height: auto;
            max-width: 35px;
            margin-right: 5px;
        }
        color: $text_light;
        font-weight: 500;
        margin-top: 15px;
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 30px;
        .name{
            line-height: 1.2;
            font-size: 14px;
        }

    }

    .el{
        &-menu-item{
            &.is-active{
                background-color: #ffaa00;
                color: $text-light;
            }
        }
        &-menu-item:hover{
            background-color: #71d6dc;
        }

        &-submenu{
            &.is-active{

            }
            .el-menu-item{
                &:focus,&:hover{
                    background-color: rgba(255, 170, 0, 0.88);
                    color: $text_navbar;
                    a{
                        color: $text_navbar;
                    }
                }
                &.is-active{
                    background-color: #ffaa00;
                    a{
                        color: $text_navbar;
                    }
                }
                a{
                    color: $text_navbar_sub;
                }
            }
            a{
                color: $text_navbar_sub;
            }
            &__title{
                i{
                    color: $text_navbar;
                }
                color: $text_navbar;
            }
        }
        &-menu{
            background: none;
            border: none;
            &-item{
                padding: 0 15px;
                a{
                    i{
                        color: $text_navbar;
                    }
                    color: $text_navbar;
                }
                i{
                    color: $text_navbar;
                }
                color: $text_navbar;
            }
        }
    }
}
.sub-menu{
    a{
        color: $text_navbar;
    }
}
.menu-parent{
    font-size: 16px;
}
.menu-icon-mobile{
    display: inline-block;
    @include media-breakpoint-up(md){
        display: none;
    }
    a{
        &.el-link--default{
            text-decoration: none;
            font-size: 22px;
            color: $text_light;
            &:hover{
                color: $text_light;
            }
        }

    }
}