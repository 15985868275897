// Body
$header_bg_color : #102e6a;
$primary_color : #09224e;
$secondary_color : #194397;
$bg_color : #f6f9fd;
$text_color : #000000;
$text_light : #fff;
$text_navbar : #fff;
$text_navbar_sub : #d5e0f6;
$text_navbar_hover : #fff;
$text_hover_color : #00A1E4;

//Border
$border-color : rgba(112,112,112,0.65);

// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Social
$google: #DC4E41;
$facebook: #475993;

$grid-breakpoints: (
    xxxs: 0,
    xxs: 320px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1600px
);
$container-max-widths: (
    xxxs: 0,
    xxs: 320px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1600px
);
$roboto-font-path: '../fonts' !default;
