.header{
    &.el-header{
        display: flex;
        align-items: center;
        background-color: $header_bg_color;
        color: $text_light;
    }
    .logo{
        font-weight: 600;
    }
    .header {
        padding: 0.3rem 0;
    }
    .logo{
        padding: 5px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        i{
            color: #777;
            cursor: pointer;
            @include media-breakpoint-up(sm){
                display: none;
            }
        }
        i:hover{
            color: #fff;
        }
        img{
            height: auto;
            max-width: 50px;
            margin-right: 5px;
        }
    }
    .profile{
        display: flex;
        padding: 0;
        align-items: center;
        color: $text_color;
        .el-avatar{
            margin-right: 5px;
        }
        .displayName{
            color: white;
            @include media-breakpoint-down(sm){
                display: none;
            }
        }
        .dropdown-toggle::after{

        }
    }
}
.supportarea{
    overflow: hidden;
    border-bottom: 1pt solid #99BBE8;
    border-top: 1pt solid #99BBE8;
    background: #FCECF1;
    >div{
        display: inline-block;
        float: left;
        line-height: 30px;
        padding-right: 20px;
        font-weight: 500;
    }
    .labelsp{
        color: #C00;
        padding-left: 20px;
    }
    .email{
        padding-left: 5px;
        border-left: 1pt solid #ccc;
        border-right: 1pt solid #ccc;
    }
}

.notify{
    &--square{
        background-color: $text_light;
        color: $primary_color;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        border-radius: 3px;
    }
}
.logo{
    display: flex;
    align-items: center;
    justify-content: center;

}
.userinfo{
    .el-link--inner{
        color: $text_light;
    }
    &:hover{
        .el-link--inner{
            color: $text_light;
        }
        color: $text_light;
    }

}
.logout{
    a{
        &:hover{
            color: $text_light;
        }
        color: $text_light;
    }
}
.user-logout{
    font-size: 26px;
}
.user-name{
    font-size: 16px;
    font-weight: 500;
}
.menu-together{
    z-index: 999;
    a{
        text-decoration: none;
    }
    .circle-1{
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $bg_color;
        border-radius: 50%;
    }
    .circle-2{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $header_bg_color;
        color: $text_light;
        font-size: 20px;
        border-radius: 50%;
        font-weight: 700;
    }
}