.configSo{
  &--phong{
    > .el-table__body-wrapper{
      > .el-table__body{
        > tbody{
          >.el-table__row{
            &:hover{
              >td{
                background-color: #71d6dc !important;
              }
            }
            background-color: #71d6dc !important;
          }
          >tr.el-table__row--striped td{
            background-color: #71d6dc !important;
            &:hover{
              >td{
                background-color: #71d6dc !important;
              }
            }
          }

        }
      }
    }

    .expanded,  .expanded:hover{
      > td{
        background-color: #71d6dc !important;
      }
      background-color: #71d6dc;

    }
    .el-table__expanded-cell{
      padding: 0;
      padding-left: 48px;
      .el-table__header-wrapper{

      }
    }
  }
  
  &--phongCustom{
    > .el-table__body-wrapper{
      > .el-table__body{
        > tbody{
          >.el-table__row{
            &:hover{
              >td{
                background-color: #71d6dc !important;
              }
            }
            background-color: #71d6dc !important;
          }
          >tr.el-table__row--striped td{
            background-color: #71d6dc !important;
            &:hover{
              >td{
                background-color: #71d6dc !important;
              }
            }
          }

        }
      }
    }

    .expanded,  .expanded:hover{
      > td{
        background-color: #71d6dc !important;
      }
      background-color: #71d6dc;

    }
    .el-table__expanded-cell{
      padding: 0;
      padding-left: 0px;
      .el-table__header-wrapper{

      }
    }
  }
  
  &--truong{
    .el-table__header-wrapper{
      display: none;
    }
  }
  
  &--truongCustom{
    .el-table__header-wrapper{
      display: none;
    }
    .el-table__expanded-cell{
      padding: 0;
      padding-left: 48px;
      .el-table__header-wrapper{

      }
    }
    > .el-table__body-wrapper{
      > .el-table__body{
        > tbody{
          >.el-table__row{
            &:hover{
              >td{
                background-color: #cff7c7 !important;
              }
            }
            background-color: #cff7c7 !important;
          }
          >tr.el-table__row--striped td{
            background-color: #cff7c7 !important;
            &:hover{
              >td{
                background-color: #cff7c7 !important;
              }
            }
          }

        }
      }
    }
  }
}
.thongTinKyTheoMonHoc{
  min-width: 375px;
}
.el-table .warning-row {
  background: #FFF !important;
}
.el-table .success-row {
  background: #a9ec84  !important;
}

.tree-default>.tree-container-ul>.tree-node{
  padding-bottom:10px;
}
.tree-default .tree-selected{
  background: none !important;
}
.el-main{
  padding:0
}
.el-submenu .el-menu-item{
  padding: 0 20px;
}
.el-submenu__title{
  padding:0 15px 0 0 
}
.el-menu-item, .el-submenu__title{
  height: 50px;
  line-height:50px
}
.logoHocBa{
  background: #71d6dc !important;
  padding-left: 6px !important;
}
.el-submenu__title:hover{
  background-color: #71d6dc !important;
}
.ant-layout-content{
  overflow: scroll;
}
.ant-layout-header{
  background-color: #0b4c5f !important;
  color: #FFF;
  height: 45px !important;
  line-height: 28px !important;
  padding: 0 15px !important;
}
.el-menu-item, .el-submenu__title{
  line-height: 35px;
  height: 40px;
}
.ant-menu{
  background: whitesmoke !important;
}
a.el-submenu__title.linkCon{
  padding-left: 40px  !important;
}
.ant-menu-submenu-title{
  padding-left: 34px !important;
}
.ant-layout-sider-trigger{
  background:#bcbfc3 !important
}