.dts-card-header {
  background: $primary_color;
  height: 30px;
  color: $text_light;
  padding: 5px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  align-items: center;
}

.dts-card-title {
  color: $text_light;
  padding-left: 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  width: 95%
}

.dts-card-body {
  padding: 5px;
  background: white;
  @include media-breakpoint-up(md){
    height: 300px;
  }
  position: relative;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border: #f2f3f8 2px solid;
}

.dts-card-body-ct {
  padding: 5px;
  background: white;
  height: 350px;
  position: relative;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border: #f2f3f8 2px solid;
}

.dts-right {
  float: right;
  padding: 0 5px;
  width: 25%;
  text-align: right;
}

.dts-right a {
  color: $text_navbar_sub;
}


.card-bieu-do {
  /* border: 1px solid #088A4B; */
  margin: 15px 0;
  background: white;
  padding: 15px;
  border-radius: 10px;
}

.card-bieu-do h5 {
  font-size: 18px;
  padding-left: 10px;
  color: blue;
  font-weight: 700
}