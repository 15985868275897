.user{
    .listUser{
        .userName{
            span{
                margin-right: 5px;
            }
        }
    }
    .el-table--enable-row-transition .el-table__body td{
        padding: 5px 0;
    }
}
